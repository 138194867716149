@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom CSS */
.yellow {
  color: #eea851;
}

/* nav */
nav a {
  font-family: "seravek-web", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}

h1,
h2 {
  font-family: "seravek-web", sans-serif;
  font-weight: 600;
  font-style: normal;
}

p {
  font-family: "seravek-web", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 15px;
}

.seravek {
  font-family: "seravek-web", sans-serif;
}

.yellowbtn {
  background: #eea851;
  color: #000;
  padding: 3px 12px;
  font-family: "seravek-web", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  display: table;
  border: 2px solid #eea851;
}

.yellowbtn:hover {
  background: #514d4b;
  color: #fff;
}

footer a:hover {
  color: white;
}

footer a {
  font-family: "seravek-web", sans-serif;
  font-weight: 400;
  font-style: normal;
}

footer .midfooterlinks {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}

#footerlogo {
  max-width: 300px;
  margin: 0 auto;
}

.footerform {
  background: #eea851;
  border: 1px solid #000;
  display: flex;
  justify-content: space-between;
  border-radius: 7px;
  max-width: 300px;
  margin: 0 auto;
}

.footerform input[type="email"],
.footerform input[type="email"]::placeholder {
  background: transparent;
  color: #000;
  font-family: "seravek-web", sans-serif;
  font-weight: 600;
  font-style: normal;
  padding: 8px 12px;
  font-size: 13px;
}

.footerform input[type="submit"] {
  background: #514d4b;
  color: #eea851;
  padding: 8px 12px;
  font-family: "seravek-web", sans-serif;
  font-size: 13px;
}

.sociallinks {
  display: flex;
  justify-content: center;
  gap: 14px;
  margin-top: 16px;
}

.sociallinks a img {
  width: 30px;
  height: 30px;
}

.copyrightlinks a:hover {
  color: #514d4b;
}

#mobmenu,
#mobmenu a {
  font-family: "seravek-web", sans-serif;
  font-weight: 700;
}

.languagetoggle {
  position: absolute;
  color: #fff;
}

.languagetoggle a {
  color: #fff;
}
